import { FacetGroup, ProductSearchResult, SelectedFacetGroup } from '../../../types/search.types';

export type FacetListState = {
	groupExpansion: { [key: string]: boolean };
	facetGroups: FacetGroup[];
	selectedFacetGroups: SelectedFacetGroup[];
	loading: boolean;
};

export type FacetListAction =
	| { type: 'toggleGroupExpanded'; id: string }
	| { type: 'expandAllGroups' }
	| { type: 'collapseAllGroups' }
	| { type: 'loadingData' }
	| { type: 'loadData'; searchResults?: ProductSearchResult };

type FacetListReducerInitializerProps = {
	searchResults?: ProductSearchResult;
	loading: boolean;
};

export const initialFacetReducerState: FacetListState = {
	groupExpansion: {},
	facetGroups: [],
	selectedFacetGroups: [],
	loading: false
};

export function facetListReducer(state: Readonly<FacetListState>, action: FacetListAction): FacetListState {
	switch (action.type) {
		case 'toggleGroupExpanded':
			return setGroupExpansion(state, action.id, !state.groupExpansion[action.id]);
		case 'expandAllGroups':
			return setAllGroupsExpansion(state, true);
		case 'collapseAllGroups':
			return setAllGroupsExpansion(state, false);
		case 'loadingData':
			return { ...state, loading: true };
		case 'loadData':
			return handleLoadData(state, action);
		default:
			return state;
	}
}

export function facetListReducerInitializer({ searchResults, loading }: FacetListReducerInitializerProps) {
	return {
		...facetListReducer(initialFacetReducerState, { type: 'loadData', searchResults }),
		loading
	};
}

function setGroupExpansion(state: Readonly<FacetListState>, id: string, expanded: boolean) {
	return {
		...state,
		groupExpansion: {
			...state.groupExpansion,
			[id]: expanded
		}
	};
}

function setAllGroupsExpansion(state: Readonly<FacetListState>, expanded: boolean) {
	return {
		...state,
		groupExpansion: state.facetGroups.reduce((result, { id }) => {
			result[id] = expanded;
			return result;
		}, {})
	};
}

function handleLoadData(state: Readonly<FacetListState>, action: Extract<FacetListAction, { type: 'loadData' }>) {
	if (!action.searchResults) {
		return {
			...state,
			loading: false
		};
	}
	const groupExpansion = getGroupExpansionOnDataLoad(state, action.searchResults);
	const { facetGroups, selectedFacetGroups } = processFacetsOnDataLoad(action.searchResults);
	return {
		...state,
		groupExpansion,
		facetGroups,
		selectedFacetGroups,
		loading: false
	};
}

function processFacetsOnDataLoad(searchResults: ProductSearchResult) {
	let facetGroups = searchResults.facetGroups;
	const selectedFacetGroups = searchResults.selectedFacetGroups;

	// If no facets are available, but there are selected facets, present the selected facets so the user can unselect them.
	if (facetGroups.length === 0 && selectedFacetGroups.length) {
		facetGroups = [];
		selectedFacetGroups.forEach((selectedGroup) => {
			facetGroups.push({
				id: selectedGroup.id,
				name: selectedGroup.name,
				facets: selectedGroup.facets.map((selectedFacet) => ({
					facetId: selectedFacet.facetId,
					groupId: selectedGroup.id,
					value: selectedFacet.value,
					count: 0
				})),
				range: selectedGroup.range,
				info: null,
				metadata: { hasMoreFacets: false },
				unitPrefix: selectedGroup.unitPrefix,
				unitSuffix: selectedGroup.unitSuffix
			});
		});
	}

	return { facetGroups, selectedFacetGroups };
}

/**
 * Expands each selected facet group if it wasn't closed by the user, unless it previously had no selected facets.
 */
function getGroupExpansionOnDataLoad(state: Readonly<FacetListState>, searchResults: ProductSearchResult) {
	if (!searchResults.selectedFacetGroups.length) {
		return state.groupExpansion;
	}
	const groupExpansion = { ...state.groupExpansion };
	searchResults.selectedFacetGroups.forEach((group) => {
		if (groupExpansion[group.id] === undefined || !state.selectedFacetGroups.some((prevGroup) => prevGroup.id === group.id)) {
			groupExpansion[group.id] = true;
		}
	});
	return groupExpansion;
}
