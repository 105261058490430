import React, { FunctionComponent } from 'react';
import { RANGE_FACET_VALUE_SEPARATOR } from '../../../constants/search';
import { isRangeFacetGroup } from '../../../helpers/search-helper/search-helper';
import { useSearchResults, UseSearchResultsPayload } from '../../../hooks/apollo/search/search.hooks';
import { SelectedFilterPill } from '../new-facet-filter-list/selected-filter-pill/selected-filter-pill.component';

export type FacetFilterListV2Props = { useResults?: () => UseSearchResultsPayload };
export const FacetFilterListV2: FunctionComponent<FacetFilterListV2Props> = ({ useResults = useSearchResults }) => {
	const { results, previousResults, removeFacet, loading } = useResults();
	const selectedFacetGroups = (loading ? previousResults : results)?.selectedFacetGroups ?? [];

	return selectedFacetGroups.length ? (
		<div className="flex">
			{selectedFacetGroups.map((group) => {
				const isRangeGroup = isRangeFacetGroup(group);
				let rangeFacet, rangeMin, rangeMax;

				if (isRangeGroup) {
					rangeMin = group.range?.min;
					rangeMax = group.range?.max;
					rangeFacet = {
						facetId: group.facets[0].facetId,
						value: `${rangeMin}${RANGE_FACET_VALUE_SEPARATOR}${rangeMax}`,
						autoApplied: group.facets[0].autoApplied
					};
				}

				return (
					<div key={group.id} className="flex">
						{isRangeGroup ? (
							<SelectedFilterPill
								key={`${group.id}:${rangeMin}-${rangeMax}`}
								facet={rangeFacet}
								group={group}
								removeFacet={removeFacet}
							/>
						) : (
							group.facets.map((facet) => (
								<SelectedFilterPill
									key={`${facet.facetId}:${facet.value}`}
									facet={facet}
									group={group}
									removeFacet={removeFacet}
								/>
							))
						)}
					</div>
				);
			})}
		</div>
	) : null;
};
