import React, { FunctionComponent, PropsWithChildren, useCallback, useEffect } from 'react';
import { FieldError, RegisterOptions, useForm } from 'react-hook-form';
import { generateDataSelector } from '../../../helpers/general-helper/general-helper';
import { formatFacetNumber, formatRangeNumber, isUnitMoney } from '../../../helpers/search-helper/search-helper';
import { StyledButton } from '../../buttons/styled-button/styled-button.component';
import { buttonWrapper } from './range.css';

export const ERROR_RANGE_MAX_LESS_THAN_MIN = 'Max value cannot be smaller than min value';
export const ERROR_RANGE_MIN_MAX_REQUIRED = 'Please enter min and max values';
export const ERROR_INVALID_NUMBER = 'Please enter a number';

const INPUT_CLEAN_REGEX = /[,$]/g;

function getInputNumberWithDefault(value: string, defaultValue?: number) {
	const cleanValue = value.replace(INPUT_CLEAN_REGEX, '').trim();
	return Number(cleanValue || defaultValue);
}

function numberWithDefaultValidator(value: string, defaultValue?: number) {
	const inputNumber = getInputNumberWithDefault(value, defaultValue);
	return Number.isFinite(inputNumber) || ERROR_INVALID_NUMBER;
}

type RangeFormData = {
	minimum: string;
	maximum: string;
	form: string; // Placeholder to set form-level errors using setError.
};

export type RangeProps = {
	onRangeSubmit: (min: string, max: string) => void;
	min?: number;
	max?: number;
	selectedMin?: number;
	selectedMax?: number;
	isSelected: boolean;
	unitPrefix?: string;
	unitSuffix?: string;
	groupName: string;
	isNewLookAndFeel?: boolean;
};

export const Range: FunctionComponent<RangeProps> = ({
	min,
	max,
	selectedMin,
	selectedMax,
	isSelected,
	unitPrefix,
	unitSuffix,
	groupName,
	isNewLookAndFeel,
	onRangeSubmit
}) => {
	const isMoney = isUnitMoney(unitPrefix);
	const getDefaults = useCallback(
		() =>
			isSelected
				? { minimum: formatRangeNumber(selectedMin, isMoney), maximum: formatRangeNumber(selectedMax, isMoney) }
				: { minimum: '', maximum: '' },
		[isMoney, isSelected, selectedMin, selectedMax]
	);
	const defaultValues = getDefaults();

	const {
		register,
		handleSubmit,
		formState: {
			errors: { minimum: minError, maximum: maxError, form: formError }
		},
		clearErrors,
		setError,
		reset,
		trigger
	} = useForm<RangeFormData>({
		mode: 'onBlur',
		reValidateMode: 'onBlur',
		criteriaMode: 'firstError',
		shouldFocusError: true,
		defaultValues
	});

	// Reset form values if selected facet values from props change.
	useEffect(() => reset(getDefaults()), [reset, getDefaults]);

	const onFormSubmit = ({ minimum, maximum }: RangeFormData, e: React.FormEvent) => {
		// Require at least one of minimum or maximum to be filled in.
		if (minimum === '' && maximum === '') {
			e.preventDefault();
			setError('form', { type: 'atLeastOne', message: ERROR_RANGE_MIN_MAX_REQUIRED });
			return;
		}

		// If one end of the range is not populated, default it to the placeholder value.
		const resolvedMin = getInputNumberWithDefault(minimum, min);
		const resolvedMax = getInputNumberWithDefault(maximum, max);

		// Max cannot be less than min.
		if (resolvedMin > resolvedMax) {
			e.preventDefault();
			setError('form', { type: 'validRange', message: ERROR_RANGE_MAX_LESS_THAN_MIN });
			return;
		}

		// Format values to enforce max decimal places.
		const submitMin = formatRangeNumber(resolvedMin, isMoney);
		const submitMax = formatRangeNumber(resolvedMax, isMoney);
		onRangeSubmit(submitMin, submitMax);
		clearErrors();
	};

	const inputRegisterConfig: RegisterOptions = {
		validate: {
			isValidNumber: (value) => numberWithDefaultValidator(value, min)
		}
	};

	const clearFacet = () => onRangeSubmit('', ''); // Submit empty strings to remove the range facet.
	const revalidate = () => {
		clearErrors();
		return trigger();
	};

	const [errorToShow, errorClass] = [
		[minError, 'tl'],
		[maxError, 'tr'],
		[formError, 'tc']
	].find((pair): pair is [FieldError, string] => Boolean(pair[0])) ?? [null, ''];

	return (
		<form aria-label={`${groupName} Filter`} onSubmit={handleSubmit(onFormSubmit)} className={isNewLookAndFeel ? 'ph2 pb2' : 'pa3'}>
			<div className={`flex ${isNewLookAndFeel ? 'gc2' : ''}`}>
				<RangeInput
					label="Min."
					aria-label={`Minimum ${groupName}`}
					placeholder={formatFacetNumber(min, isMoney)}
					prefix={unitPrefix}
					suffix={unitSuffix}
					invalid={Boolean(minError)}
					data-automation="range-minimum"
					className="flex-auto"
					isNewLookAndFeel={isNewLookAndFeel}
					{...register('minimum', {
						...inputRegisterConfig,
						onChange: () => (minError || formError) && revalidate()
					})}
				/>
				{!isNewLookAndFeel && <div className="ph2 pt3 mt3"> to </div>}
				<RangeInput
					label="Max."
					aria-label={`Maximum ${groupName}`}
					placeholder={formatFacetNumber(max, isMoney)}
					prefix={unitPrefix}
					suffix={unitSuffix}
					invalid={Boolean(maxError)}
					data-automation="range-maximum"
					className="flex-auto"
					isNewLookAndFeel={isNewLookAndFeel}
					{...register('maximum', {
						...inputRegisterConfig,
						onChange: () => (maxError || formError) && revalidate()
					})}
				/>
			</div>
			<div className={`theme-error pv1 f7 lh-solid ${errorClass}`}>{errorToShow?.message ?? <>&nbsp;</>}</div>
			<div className={`w-100 flex ${buttonWrapper}`}>
				{isSelected && (
					<StyledButton testId="range-clear" onClick={clearFacet} buttonStyle="SECONDARY" data-automation="range-clear">
						Clear
					</StyledButton>
				)}
				<StyledButton
					testId="range-apply"
					buttonType="submit"
					buttonStyle="SECONDARY"
					disabled={Boolean(errorToShow)}
					data-automation="range-apply">
					Apply
				</StyledButton>
			</div>
		</form>
	);
};

export type RangeInputProps = React.HTMLProps<HTMLInputElement> & {
	prefix?: string;
	suffix?: string;
	description?: string;
	invalid?: boolean;
	invalidMessage?: string;
	automationHook?: string;
	analyticsHook?: string;
	testId?: string;
	isNewLookAndFeel?: boolean;
};

export const RangeInput = React.forwardRef<HTMLInputElement, RangeInputProps>(
	(
		{
			prefix,
			suffix,
			label,
			className = '',
			id,
			invalid,
			required = false,
			testId,
			automationHook,
			analyticsHook,
			isNewLookAndFeel,
			...remainingProps
		},
		ref
	) => {
		const borderClasses = `ba br2 ${invalid ? 'b--theme-error' : 'b--theme-grey-light'}`;
		const inputStyle = {
			paddingLeft: isNewLookAndFeel ? getInputPaddingSizeNewLook(prefix) : getInputPaddingSize(prefix),
			paddingRight: isNewLookAndFeel ? getInputPaddingSizeNewLook(suffix) : getInputPaddingSize(suffix)
		};

		return (
			<div className={`relative ${className}`}>
				<span
					className={`disableClickEvents absolute top-0 f6 ${
						invalid ? 'theme-error' : isNewLookAndFeel ? 'theme-primary-dark' : 'theme-primary'
					} ${isNewLookAndFeel ? 'mt2 ml3 pt1' : 'mt2 ml2 pl1'}`}
					aria-hidden="true">
					{label}
				</span>
				<RangeInputAffix className={`left-0 ml2 ${isNewLookAndFeel ? 'pl2' : 'pl1'}`}>{prefix}</RangeInputAffix>
				<input
					id={id}
					className={`input-reset input pt5 w-100 theme-grey-darker f5 lh-title truncate sans-serif ${borderClasses}`}
					style={inputStyle}
					required={required}
					data-testid={testId}
					data-automation={generateDataSelector('input', automationHook)}
					data-analytics={generateDataSelector('input', analyticsHook)}
					ref={ref}
					{...remainingProps}
					type="text"
					inputMode="numeric"
				/>
				<RangeInputAffix className="right-0 mr2 pr1">{suffix}</RangeInputAffix>
			</div>
		);
	}
);

const RangeInputAffix: FunctionComponent<PropsWithChildren<{ className?: string }>> = ({ children, className = '' }) => {
	if (!children) {
		return null;
	}
	return <span className={`disableClickEvents absolute top-0 input pt5 lh-title bt b--transparent ${className}`}>{children}</span>;
};

/**
 * Leave space for the affix to overlay the end of the input, based on number of characters in the string.
 */
function getInputPaddingSize(affix?: string) {
	return affix?.length ? `calc(1rem + ${affix.length * 1.2}ch)` : '0.75rem';
}

/**
 * Leave space for the affix to overlay the end of the input.
 */
function getInputPaddingSizeNewLook(affix?: string) {
	return affix?.length ? `calc(1rem + ${getAffixPaddingSize(affix)})` : '1rem';
}

/**
 * Tweak the spacing for some common cases, and otherwise fall back on a generic calculation.
 */
function getAffixPaddingSize(affix: string) {
	switch (affix) {
		case '$':
			return '1.2ch';
		case 'in.':
			return '1.6ch';
		default:
			return `${affix.length * 1.1}ch`;
	}
}
