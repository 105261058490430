import { logError } from 'fergy-core-react-logging';
import React, { FunctionComponent } from 'react';
import { FEATURE_FLAGS } from '../../../constants/general';
import { DEFAULT_PRODUCT_SORT, SEARCH_RESULT_LIMIT, SEARCH_SORT_OPTIONS, STOCK_STATUS_OPTIONS } from '../../../constants/search';
import { booleanFromQueryString, formatNumber } from '../../../helpers/general-helper/general-helper';
import { isRangeFacetGroup } from '../../../helpers/search-helper/search-helper';
import { convertToProductSortEnum } from '../../../helpers/search-helper/search-product-helper';
import { useProductViewPreference, useSearchResults, UseSearchResultsPayload } from '../../../hooks/apollo/search/search.hooks';
import { useFeature } from '../../../hooks/features/features.hooks';
import { StyledButton } from '../../buttons';
import { Badge } from '../../common-components/badge/badge.component';
import { SelectBox, SelectOptionProps } from '../../inputs/select-box/select-box.component';
import { ArrowDropDownIcon, GridViewIcon, FormatListBulletedIcon, SortIcon } from '../../svg/icons.component';

export type SearchHeaderProps = {
	title?: string;
	sortOptions?: SelectOptionProps[];
	useCategorySort?: boolean;
	showNonstock?: boolean;
	onShowDrawer: () => void;
	useResults?: () => UseSearchResultsPayload;
};

export const SearchHeader: FunctionComponent<SearchHeaderProps> = ({
	title,
	onShowDrawer,
	showNonstock,
	sortOptions = SEARCH_SORT_OPTIONS,
	useResults = useSearchResults
}) => {
	const { results, loading, query, sortBy, setSortBy, isNonstock, setNonstock } = useResults();
	const { productViewPreference, setProductViewPreference } = useProductViewPreference();
	const toggleView = () => {
		setProductViewPreference(productViewPreference === 'TILE' ? 'LIST' : 'TILE').catch(logError);
	};
	const count = results?.count || 0;

	let resultCount = formatNumber(Math.min(count, SEARCH_RESULT_LIMIT));
	if (count > SEARCH_RESULT_LIMIT) {
		resultCount = `${resultCount}+`;
	}
	const isSortInsideFacetDrawer = useFeature(FEATURE_FLAGS.SORT_INSIDE_FACET_DRAWER);

	const selectedFacetCounts =
		results?.selectedFacetGroups?.reduce((facetCount, group) => {
			const isRangeGroup = isRangeFacetGroup(group);
			return facetCount + (isRangeGroup ? 1 : group.facets?.length ?? 0);
		}, 0) ?? 0;

	const sortChanged = (event: React.ChangeEvent<HTMLSelectElement>) => {
		const sort = convertToProductSortEnum(event.target.value);
		setSortBy(sort);
	};
	const filterClick = () => {
		onShowDrawer();
	};
	const stockStatusChanged = (event: React.ChangeEvent<HTMLSelectElement>) => {
		const nonstock = booleanFromQueryString(event.target.value);
		setNonstock(nonstock);
	};

	const facetCountText = selectedFacetCounts > 0 ? `(${selectedFacetCounts})` : '';

	const hasSortBy = sortBy !== DEFAULT_PRODUCT_SORT;
	const facetAndSortCounts = hasSortBy ? selectedFacetCounts + 1 : selectedFacetCounts;
	const facetAndSortCountText = facetAndSortCounts > 0 ? `(${facetAndSortCounts})` : '';

	const displayedFacetCount = isSortInsideFacetDrawer ? facetAndSortCountText : facetCountText;
	const sortSelectClassName = isSortInsideFacetDrawer ? 'dn db-ns' : '';

	return (
		<div id="search-header" className="flex flex-wrap justify-between-ns">
			<div className="w-100 w-auto-ns">
				{results?.correctedSearchTerm && (
					<div className="ma3">
						We didn't find any products for <span className="theme-error">{query}</span>. Showing results for...
					</div>
				)}
				<h1 className="dib ma0" data-automation="search-header-text">
					{title}
				</h1>
				<div className={`mt1 mt0-l mb1 mb0-l ml2-l dib-l ${loading ? 'o-0' : ''}`} data-automation="results-count">
					{resultCount} Products
				</div>
			</div>
			<div className="flex flex-wrap">
				{showNonstock && (
					<span className={`mr2 ${isSortInsideFacetDrawer ? 'flex db-ns' : 'mb1'}`}>
						<SelectBox
							name="sortSelect"
							value={String(isNonstock)}
							options={STOCK_STATUS_OPTIONS}
							automationHook="product-stock-status"
							ariaLabel="stock status"
							onChange={stockStatusChanged}
							data-testid="stock-status-select"
							employee={true}
						/>
					</span>
				)}
				<span className={`mr2 mb1 ${sortSelectClassName}`}>
					<SelectBox
						name="sortSelect"
						value={sortBy}
						options={sortOptions}
						automationHook="product-sort-by"
						ariaLabel="sort"
						onChange={sortChanged}
						data-testid="sort-select"
						borderStyle="dark"
					/>
				</span>
				{isSortInsideFacetDrawer && (
					<span className={`dn-ns ${facetAndSortCounts > 0 ? 'mr3' : 'mr2'} ${showNonstock ? 'mt1 mt0-ns' : undefined}`}>
						<Badge count={facetAndSortCounts} noMargin>
							<StyledButton onClick={filterClick} buttonStyle="SECONDARY">
								<div className="flex items-center">
									<SortIcon className="f4" />
									<span className="mh2">{`Sort & Filter`}</span>
									<ArrowDropDownIcon className="f3" />
								</div>
							</StyledButton>
						</Badge>
					</span>
				)}
				<span className={`dn-l mr2 mb1 ${isSortInsideFacetDrawer ? 'dn db-m' : ''}`}>
					<StyledButton onClick={filterClick} buttonStyle="SECONDARY">
						<span>{`Filter ${displayedFacetCount}`}</span>
					</StyledButton>
				</span>
				<span
					className={`${isSortInsideFacetDrawer ? 'flex db-ns' : undefined} ${
						showNonstock && isSortInsideFacetDrawer ? 'mt1 mt0-ns' : undefined
					}`}>
					<StyledButton automationHook={productViewPreference} onClick={toggleView} buttonStyle="SECONDARY">
						<div className="flex items-center">
							<div className="flex items-center">
								{productViewPreference === 'LIST' ? <FormatListBulletedIcon /> : <GridViewIcon />}
							</div>
							<span className="ml2">{productViewPreference === 'LIST' ? 'List' : 'Grid'} View</span>
						</div>
					</StyledButton>
				</span>
			</div>
		</div>
	);
};
